import { createRouter, createWebHistory } from 'vue-router'
import { auth } from "@/lib/services/auth.service.js";
import store from '@/lib/store';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '',
      name: 'home',
      component: () => import('@/views/index.vue'),
      redirect: `/emails`,
      children: [
        // {
        //   path: "home",
        //   meta: {
        //     search: false
        //   },
        //   component: () => import("@/views/Welcome"),
        // },
        // {
        //   path: "overview",
        //   component: () => import("@/views/overview/Overview"),
        // },
        {
          path: "emails",
          redirect: `/emails/inbox`,
          children: [
            {
              path: "inbox",
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: "inbox/:id",
              props: true,
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: ":id/reply",
              props: true,
            },

            {
              path: "new",
              props: true,
              component: () => import("@/views/emails/NewMail.vue"),

            },
            {
              path: "waitingOn",
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: "important",
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: "overdue",
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: "archived",
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: "draft",
              component: () => import("@/views/emails/Draft.vue"),
            },
            {
              path: "sent",
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: "black-hole",
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: "deleted",
              component: () => import("@/views/emails/Inbox.vue"),
            },
            {
              path: "spam",
              component: () => import("@/views/emails/Spam.vue"),
            },
          ]
        },
        {
          path: "tasks",
          redirect: `/tasks/today`,

          children: [
            {
              path: "today",
              component: () => import("@/views/tasks/Tasks.vue"),
            },
            {
              path: "waitingOn",
              component: () => import("@/views/tasks/Tasks.vue"),
            },
            {
              path: "important",
              component: () => import("@/views/tasks/Tasks.vue"),
            },
            {
              path: "completed",
              component: () => import("@/views/tasks/Tasks.vue"),
            },
            {
              path: "all",
              component: () => import("@/views/tasks/Tasks.vue"),
            },
            {
              path: "next7days",
              component: () => import("@/views/tasks/Tasks.vue"),
            },
            {
              path: "next30days",
              component: () => import("@/views/tasks/Tasks.vue"),
            },
            {
              path: "someday",
              component: () => import("@/views/tasks/Tasks.vue"),
            },

          ]
        },
        {
          path: "events",
          redirect: `/events/all-events`,
          children: [
            {
              path: "all-events",
              component: () => import("@/views/events/Events.vue"),
            },
            {
              path: "important",
              component: () => import("@/views/events/Events.vue"),
            },
            {
              path: "recurring",
              component: () => import("@/views/events/Events.vue"),
            },
            {
              path: "past",
              component: () => import("@/views/events/Events.vue"),
            },
            {
              path: "calendar",
              component: () => import("@/views/events/Calendar.vue"),
            },
          ]
        },
        {
          path: "notes",
          redirect: `/notes/all-notes`,
          children: [
            {
              path: "all-notes",
              component: () => import("@/views/notes/Notes.vue"),
            },
            {
              path: "important",
              component: () => import("@/views/notes/Notes.vue"),
            },
            {
              path: "preset",
              component: () => import("@/views/notes/Notes.vue"),
            },
          ]
        },
        {
          path: "contacts",
          redirect: `/contacts/all-contacts`,
          children: [
            {
              path: "all-contacts",
              component: () => import("@/views/contacts/Contacts.vue"),
            }
          ]
        },
        {
          path: "files",
          redirect: `/files/local`,
          children: [
            {
              path: "local",
              component: () => import("@/views/files/Files.vue"),
            },
            {
              path: "google-drive",
              component: () => import("@/views/files/Files.vue"),
            },
            {
              path: "dropbox",
              component: () => import("@/views/files/Files.vue"),
            },
            {
              path: "one-drive",
              component: () => import("@/views/files/Files.vue"),
            },
            {
              path: "attachments",
              component: () => import("@/views/files/Files.vue"),
            },
            {
              path: "favorites",
              component: () => import("@/views/files/Files.vue"),
            },
          ]
        },
      ]

    },
    {
    }
  ]
})

export default router;

router.beforeEach(async (to, from) => {
  const baseUri = window.location.origin

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    // ← here ↓
    if (previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }
  }
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));


  let isAuthenticated = store.getters["user/isAuthenticated"];
  if (!isAuthenticated) {
    sessionStorage.setItem("redirectedUri", baseUri + to.fullPath);
    await auth.authenticate(to.path).then(() => {
      console.log("authenticating a protected url:" + to.path);

    });
    isAuthenticated = store.getters["user/isAuthenticated"];
    if (!isAuthenticated) {
      return false;
    }
  }
  else {
    console.log('User is logged in!')
  }

});