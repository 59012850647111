import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const $toast = useToast();

const initialState = (archived = false, deleted = false, important = false, waitingOn = false, overdue = false, ContactId = null, folder = null) => ({
  timelines: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  requestData: {
    page: 1,
    pageSize: 50,
    order: 1,
    search: '',
    archived: false,
    deleted: false,
    important: false,
    waitingOn: false,
    overdue: false,
    ContactId: null,
    folder: null,
  },
  timelineCounts: {
    totalCount: 0,
    unSeenCount: 0,
    waitingOnCount: 0,
    importantCount: 0,
    overdueCount: 0,
    binCount: 0,
    draftCount: 0,
    archiveCount: 0,
    spamCount: 0,
    sentCount: 0,
  },
  isLoading: true,
  pagesLoaded: new Set(),
});

export const state = initialState();

export const getters = {
  timelines: (state) => {
    let { items, index, totalCount, totalPages, } = state.timelines;
    let { page, pageSize, } = state.requestData;
    let activeItems = items.filter(item => !item.isDeleted);

    let start = (page - 1) * pageSize;
    let end = start + pageSize;
    let paginatedNotes = activeItems.slice(start, end);

    return {
      items: paginatedNotes,
      index: page,
      totalCount: paginatedNotes.length,
      totalPages: totalPages,
      hasPreviousPage: page > 1,
      hasNextPage: end < items.length,
    };
  },
  isLoading: s => s.isLoading,
  getTimelineById: (s) => (id) => {
    return s.timelines.items.find(item => item.id === id);
  },
  timelineCounts: s => s.timelineCounts,
  archived: s => s.requestData.archived,
  deleted: s => s.requestData.deleted,
  important: s => s.requestData.important,
  waitingOn: s => s.requestData.waitingOn,
  overdue: s => s.requestData.overdue,
  ContactId: s => s.requestData.ContactId,
  folder: s => s.requestData.folder,

};
export const actions = {
  async fetchTimelinesAndCounts({ commit, dispatch, state }, newRequestData) {
    const archived = state.requestData.archived;
    const deleted = state.requestData.deleted;
    const important = state.requestData.important;
    const waitingOn = state.requestData.waitingOn;
    const overdue = state.requestData.overdue;
    const ContactId = state.requestData.ContactId;
    const folder = state.requestData.folder;

    commit('RESET_STATE', archived, deleted, important, waitingOn, overdue, ContactId, folder);
    await Promise.all([
      dispatch('fetchTimelines', newRequestData),
      dispatch('fetchTimelineCount'),
      // dispatch('fetchPresets'),
    ]);
  },
  addTaskIntoTimeline({ commit }, task) {
    commit("ADD_TASK", task);
  },
  addNoteIntoTimeline({ commit }, note) {
    commit("ADD_NOTE", note);
  },
  addEventIntoTimeline(s, event) {
    commit("ADD_EVENT", event);
  },
  async fetchTimeline({ commit }, timelineId) {
    return await api.getById(`timeline`, timelineId).then(response => {
      if (response) {
        commit("SET_TIMELINE", response.data);
      }
      return true;
    });
  },
  async fetchEmailBody({ commit }, { timelineId, emailId }) {
    return await api.getEmailBody(`email/body`, { emailId: emailId }).then(response => {
      if (response) {
        commit("SET_EMAILDETAIL", { timelineId: timelineId, emailId: emailId, data: response.data });
      }
      return true;
    })
  },
  async toggleArchive({ commit, dispatch }, request) {
    if (typeof (request) === 'string') {
      const timelineId = request
      return await api.toggle(`timeline/${timelineId}/toggleArchive`)
        .then((response) => {
          if (response) {
            commit("UPDATE_ARCHIVE", timelineId);
            dispatch('fetchTimelineCount');
            return true;
          }
          return false;
        });
    } else { // request is object
      return api.insert(`timeline/toggleArchive`, request)
        .then((response) => {
          if (response) {
            commit("UPDATE_ARCHIVE", request.ids);
            $toast.open({
              message: 'Emails Moved',
              type: 'info',
              duration: 3000,
              dismissible: true
            })
            dispatch('fetchTimelineCount');
            return true;
          }
          return false;
        });
    }
  },
  async deleteTimeline({ commit, dispatch }, request) {
    return await api.deleteWithRequest(`timeline/moveToBin`, request)
      .then((response) => {
        if (response) {
          commit("REMOVE", request.ids);
          $toast.open({
            message: 'Email Deleted',
            type: 'warning',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsImportant({ commit, dispatch }, request) {
    return api.insert(`timeline/markAsImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", { important: true, response: response.data });
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          //   dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsNotImportant({ commit, dispatch }, request) {
    return api.insert(`timeline/markAsNotImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", { important: false, response: response.data });
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          // dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async toggleRead({ commit, dispatch }, timelineId) {
    return api.toggle(`timeline/${timelineId}/toggleRead`)
      .then((response) => {
        if (response) {
          commit("UPDATE", timelineId);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsRead({ commit, dispatch }, request) {
    return api.insert(`timeline/markAsRead`, request)
      .then((response) => {
        if (response) {
          commit("MARK_AS_READ", request.ids);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsUnRead({ commit, dispatch }, request) {
    return api.insert(`timeline/markAsUnRead`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_READ", request.ids);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async toggleWaitingOn({ commit, dispatch }, request) {
    return api.insert(`timeline/toggleWaitingOn`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_WAITING", request.timelineId);
          $toast.open({
            message: 'Email Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async fetchTimelines({ commit, state }, newRequestData) {
    let requestData = null;
    if (newRequestData === null) {
      requestData = {
        page: 1,
        pageSize: 50,
        order: 1,
        search: '',
        archived: state.requestData.archived,
        deleted: state.requestData.deleted,
        important: state.requestData.important,
        waitingOn: state.requestData.waitingOn,
        overdue: state.requestData.overdue,
        ContactId: state.requestData.ContactId,
        folder: state.requestData.folder,
      }
    } else {
      requestData = newRequestData;
    }
    const currentRequestData = state.requestData;
    const pageKey = `${requestData.page}-${requestData.pageSize}-${requestData.order}-${requestData.archived ? 1 : 0}-${requestData.deleted ? 1 : 0}-${requestData.important ? 1 : 0}-${requestData.waitingOn ? 1 : 0}-${requestData.overdue ? 1 : 0}-${requestData.folder ? requestData.folder : null}`;
    if (newRequestData !== null && state.pagesLoaded.has(pageKey)) {
      commit('SET_REQUEST_DATA', requestData);
      return;
    }
    if (newRequestData === null || JSON.stringify(requestData) !== JSON.stringify(currentRequestData)) {
      commit('SET_LOADING', true);
      commit('SET_REQUEST_DATA', requestData);
      try {
        let customRequest = null;
        if (requestData.page > 1 && state.timelines.items.length === 0) {
          customRequest = {
            page: 1,
            pageSize: requestData.page * requestData.pageSize,
            order: requestData.order,
            search: requestData.search,
            archived: requestData.archived,
            deleted: requestData.deleted,
            important: requestData.important,
            waitingOn: requestData.waitingOn,
            overdue: requestData.overdue,
            folder: requestData.folder,
          }
          await fetchTimelinesWithRetry(commit, requestData, customRequest, 0);
        }
        else {
          if (requestData.page - state.timelines.index > 1)
            for (let page = state.timelines.index + 1; page <= requestData.page; page++) {
              customRequest = {
                page: page,
                pageSize: requestData.pageSize,
                order: requestData.order,
                search: requestData.search,
                archived: requestData.archived,
                deleted: requestData.deleted,
                important: requestData.important,
                waitingOn: requestData.waitingOn,
                overdue: requestData.overdue,
                folder: requestData.folder,
              };
              await fetchTimelinesWithRetry(commit, customRequest, null, 0);
            }
          else {
            await fetchTimelinesWithRetry(commit, requestData, customRequest, 0);
          }
        }

      } finally {
        commit('SET_LOADING', false);
      }
    }
  },
  async fetchTimelineCount({ commit }) {
    try {
      const response = await api.get(`timeline/count`, {});
      if (response.data) {
        commit("SET_COUNT", response.data);
        return true;
      }
      return false; // Handle case where response.data is falsy
    } catch (error) {
      console.error('Error fetching timeline count:', error);
      return false; // Return false or handle error appropriately
    }
  },
  loadImportant({ dispatch, state }, menuName) {
    let request = {
      page: 1,
      pageSize: 50,
      order: 1,
      search: '',
      important: state.requestData.important,
      archived: state.requestData.archived,
      deleted: state.requestData.deleted,
      waitingOn: state.requestData.waitingOn,
      overdue: state.requestData.overdue,
      ContactId: state.requestData.ContactId,
      folder: state.requestData.folder,
    }
    switch (menuName) {
      case 'important':
        request.important = true;
        request.archived = false;
        request.waitingOn = false;
        request.overdue = false;
        break;
      case 'inbox':
        request.important = false;
        request.archived = false;
        request.waitingOn = false;
        request.overdue = false;
        break;
      case 'archived':
        request.important = false;
        request.archived = true;
        request.waitingOn = false;
        request.overdue = false;
        break;
      case 'waitingOn':
        request.important = false;
        request.archived = false;
        request.waitingOn = true;
        request.overdue = false;
        break;
      case 'overdue':
        request.important = false;
        request.archived = false;
        request.waitingOn = false;
        request.overdue = true;
        break;
      default:
        break;
    }
    const stateRequest = state.requestData;
    const hasChanged =
      request.important !== stateRequest.important ||
      request.archived !== stateRequest.archived ||
      request.waitingOn !== stateRequest.waitingOn ||
      request.overdue !== stateRequest.overdue;

    if (hasChanged) {
      dispatch('fetchTimelines', request);
    }
  },
};

async function fetchTimelinesWithRetry(commit, requestData, customRequest, retryCount) {
  try {
    let response = null;
    let requestParams = { ...requestData };
    if (requestParams.archived === false) {
      delete requestParams.archived;
    }
    if (requestParams.deleted === false) {
      delete requestParams.deleted;
    }
    if (requestParams.important === false) {
      delete requestParams.important;
    }
    if (requestParams.waitingOn === false) {
      delete requestParams.waitingOn;
    }
    if (requestParams.overdue === false) {
      delete requestParams.overdue;
    }
    if (requestParams.ContactId === null) {
      delete requestParams.ContactId;
    }
    if (requestParams.folder === null) {
      delete requestParams.folder;
    }
    if (customRequest === null) {
      response = await api.get('timeline', requestParams);
    } else {
      let customRequestParams = { ...customRequest };
      if (customRequestParams.archived === false) {
        delete customRequestParams.archived;
      }
      if (customRequestParams.deleted === false) {
        delete customRequestParams.deleted;
      }
      if (customRequestParams.important === false) {
        delete customRequestParams.important;
      }
      if (customRequestParams.waitingOn === false) {
        delete customRequestParams.waitingOn;
      }
      if (customRequestParams.overdue === false) {
        delete customRequestParams.overdue;
      }
      if (customRequestParams.ContactId === null) {
        delete customRequestParams.ContactId;
      }
      if (customRequestParams.folder === null) {
        delete customRequestParams.folder;
      }
      response = await api.get('timeline', customRequestParams);
    }
    if (response) {
      commit("SET_TIMELINES", { data: response.data, requestData, customRequest });
    }
  } catch (error) {
    console.error('Error fetching notes:', error);
    if (retryCount < 3) {
      await new Promise(resolve => setTimeout(resolve, 10000));
      await fetchTimelinesWithRetry(commit, requestData, customRequest, retryCount + 1);
    } else {
      console.error('Max retries reached. Failed to fetch notes.');
    }
  }
}
export const mutations = {
  RESET_STATE(state, archived, deleted, important, waitingOn, overdue, ContactId, folder) {
    Object.assign(state, initialState(archived, deleted, important, waitingOn, overdue, ContactId, folder));

  },
  SET_LOADING(s, isLoading) {
    s.isLoading = isLoading;
  },
  SET_COUNT(s, response) {
    s.timelineCounts = response
  },
  REMOVE(s, ids) {
    s.timelines.items = s.timelines.items.filter(item => !ids.includes(item.id));
  },
  ADD_TASK(s, task) {
    const timeline = s.timelines.items.find(t => t.id === task.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: task.id,
        assignDate: task.createDate,
        isDeleted: task.isDeleted,
        isImportant: task.isImportant,
        itemType: 2,
        timelineId: task.timelineId,
        title: task.title
      })
      timeline.tasks.push(task);
      timeline.taskCount++;
    }
  },
  ADD_NOTE(s, note) {
    const timeline = s.timelines.items.find(t => t.id === note.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: note.id,
        assignDate: note.createDate,
        isDeleted: note.isDeleted,
        isImportant: note.isImportant,
        itemType: 4,
        timelineId: note.timelineId,
        title: note.title
      })
      timeline.notes.push(note);
      timeline.noteCount++;
    }
  },
  ADD_EVENT(s, event) {
    const timeline = s.timelines.items.find(t => t.id === event.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: event.id,
        assignDate: event.createDate,
        isDeleted: event.isDeleted,
        isImportant: event.isImportant,
        itemType: 3,
        timelineId: event.timelineId,
        title: event.title
      })
      timeline.event.push(event);
      timeline.eventCount++;
    }
  },
  SET_TIMELINE(s, timeline) {
    const index = s.timelines.items.findIndex(item => item.id === timeline.id);
    if (index === -1) {
      s.timelines.items.push(timeline);
    } else {
      s.timelines.items[index] = timeline;
    }
    s.isLoading = false;
  },
  SET_TIMELINES(s, { data, requestData, customRequest }) {
    if (requestData.page === 1) {
      s.timelines.items = data.items;
      const pageKey = `${requestData.page}-${requestData.pageSize}-${requestData.order}-${requestData.archived ? 1 : 0}-${requestData.deleted ? 1 : 0}-${requestData.important ? 1 : 0}-${requestData.waitingOn ? 1 : 0}-${requestData.overdue ? 1 : 0}-${requestData.folder ? requestData.folder : null}`;
      s.pagesLoaded.add(pageKey);


    } else {
      if (s.timelines.items.length === 0 || customRequest !== null) {
        s.timelines.items = data.items;
        for (let i = 1; i <= requestData.page; i++) {
          let pageKey = `${i}-${requestData.pageSize}-${requestData.order}-${requestData.archived ? 1 : 0}-${requestData.deleted ? 1 : 0}-${requestData.important ? 1 : 0}-${requestData.waitingOn ? 1 : 0}-${requestData.overdue ? 1 : 0}-${requestData.folder ? requestData.folder : null}`;
          s.pagesLoaded.add(pageKey);
        }
      }
      else {
        const pageKey = `${requestData.page}-${requestData.pageSize}-${requestData.order}-${requestData.archived ? 1 : 0}-${requestData.deleted ? 1 : 0}-${requestData.important ? 1 : 0}-${requestData.waitingOn ? 1 : 0}-${requestData.overdue ? 1 : 0}-${requestData.folder ? requestData.folder : null}`;
        s.pagesLoaded.add(pageKey);
        s.timelines.items = [...s.timelines.items, ...data.items];
      }
    }
    s.timelines.index = requestData.page;
    s.timelines.totalCount = data.totalCount;
    if (customRequest !== null) {
      s.timelines.totalPages = Math.ceil(data.totalCount / requestData.pageSize);
      s.timelines.hasPreviousPage = requestData.page > 1;
      s.timelines.hasNextPage = requestData.page < Math.ceil(data.totalCount / requestData.pageSize);
    }
    else {
      s.timelines.totalPages = data.totalPages;
      s.timelines.hasPreviousPage = data.hasPreviousPage;
      s.timelines.hasNextPage = data.hasNextPage;
    }

  },
  SET_EMAILDETAIL(s, { timelineId, emailId, data }) {
    const timeline = s.timelines.items.find(t => t.id === timelineId);
    const emailMessage = timeline.emailMessages.find(t => t.id === emailId);
    if (emailMessage) {
      emailMessage.contentHtml = data.contentHtml;

    }
  },
  SET_REQUEST_DATA(s, data) {
    if (s.requestData.pageSize !== data.pageSize || s.requestData.order !== data.order || s.requestData.archived !== data.archived || s.requestData.deleted !== data.deleted || s.requestData.important !== data.important || s.requestData.waitingOn !== data.waitingOn || s.requestData.overdue !== data.overdue || s.requestData.folder !== data.folder) {
      s.pagesLoaded.clear()
      s.timelines.items = []
    }
    s.requestData.page = data.page
    s.requestData.pageSize = data.pageSize
    s.requestData.order = data.order
    s.requestData.search = data.search
    s.requestData.archived = data.archived
    s.requestData.deleted = data.deleted
    s.requestData.important = data.important
    s.requestData.waitingOn = data.waitingOn
    s.requestData.overdue = data.overdue
    s.requestData.folder = data.folder
  },
  UPDATE_IMPORTANT(s, data) {

    data.response.forEach(response => {
      const timeline = s.timelines.items.find(t => t.id === response.id);
      if (timeline) {
        timeline.isImportant = data.important;
      }
    })
    const countChange = data.response.length;
    if (data.important) {
      s.timelineCounts.importantCount += countChange;
    }
    else {
      s.timelineCounts.importantCount -= countChange;
    }
  },
  MARK_AS_READ(s, response) {
    response.forEach(response => {
      let timeline = s.timelines.items.find(t => t.id === response.id);
      if (timeline) {
        timeline.seen = true;
      }
    })
  },
  MARK_AS_UNREAD(s, response) {
    response.forEach(response => {
      let timeline = s.timelines.items.find(t => t.id === response.id);
      if (timeline) {
        timeline.seen = false;
      }
    })
  },

  UPDATE_WAITING(s, timelineId) {
    const timeline = s.timelines.items.find(t => t.id === timelineId);
    if (timeline) {
      if (timeline.waitingOn === true) {
        s.timelineCounts.waitingOnCount--
      }
      else {
        s.timelineCounts.waitingOnCount++
      }
      timeline.waitingOn = !timeline.waitingOn;
    }
  },
  UPDATE_ARCHIVE(s, request) {
    if (typeof (request) === 'string') {
      const timelineId = request
      const timeline = s.timelines.items.find(t => t.id === timelineId)
      if (timeline) {
        timeline.isArchive = !timeline.isArchive
      }
      if (timeline.isArchive) {
        $toast.open({
          message: 'Email Moved To Archive',
          type: 'info',
          duration: 3000,
          dismissible: true
        })
      }
      else {
        $toast.open({
          message: 'Email Moved To Inbox ',
          type: 'info',
          duration: 3000,
          dismissible: true
        })
      }
      return (timeline.isArchive)
    } else {
      request.forEach(r => {
        let timeline = s.timelines.items.find(t => t.id === r.id)
        if (timeline) {
          timeline.isArchive = !timeline.isArchive
        }
      })
    }
  },
}
export default {
  state,
  getters,
  actions,
  mutations
};