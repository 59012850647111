import api from '@/lib/services/general.service'

export const state = {
    emails: {
        items: [],
        index: 0,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: 0,
        hasNextPage: 0,
    },
    spamEmails: {
        items: [],
        index: 0,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: 0,
        hasNextPage: 0,
    },
    draftEmails: {
        items: [],
        index: 0,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: 0,
        hasNextPage: 0,
    },
    emailsBody: [],
    isLoading: true,


}
export const getters = {
    emails: s => s.emails,
    spamEmails: s => s.spamEmails,
    draftEmails: s => s.draftEmails,
    isLoading: s => s.isLoading,
    getEmailBodyById: (s) => (uId) => {
        return s.emailsBody.find(item => item.uId === uId);
    }
};
export const actions = {

    async fetchEmails({ commit }, requestData) {
        return await api.get(`email`, requestData).then(response => {
            if (response) {
                commit("SET_EMAILS", response.data);
            }
            return true;
        });
    },
    async fetchSpamsEmails({ commit }, requestData) {
        return await api.get(`email/spam`, requestData).then(response => {
            if (response) {
                commit("SET_SPAM_EMAILS", response.data);
            }
            return true;
        });
    },
    async fetchDraftsEmails({ commit }, requestData) {
        return await api.get(`email/draft`, requestData).then(response => {
            if (response) {
                commit("SET_DRAFT_EMAILS", response.data);
            }
            return true;
        });
    },
    async fetchEmailBody({ commit }, id) {
        return await api.getWithPathById(`email`, id, 'body').then(response => {
            if (response) {
                commit("SET_EMAIL_BODY", response.data);
            }
            return true;
        });
    }
}
export const mutations = {
    SET_EMAILS(s, emails) {
        s.emails = emails;
        s.isLoading = false;
    },
    SET_SPAM_EMAILS(s, emails) {
        s.spamEmails = emails;
        s.isLoading = false;
    },
    SET_DRAFT_EMAILS(s, emails) {
        s.draftEmails = emails;
        s.isLoading = false;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
};