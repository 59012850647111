import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const $toast = useToast();

export const state = {
    contacts: {
        items: [],
        index: 0,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: 0,
        hasNextPage: 0,
    },
    contactsFilter: [],
    isLoading: true
}
export const getters = {
    contacts: s => s.contacts,
    contactsFilter: s => s.contactsFilter,
    isLoading: s => s.isLoading,
};
export const actions = {
    async fetchContacts({ commit }, requestData) {
        return await api.get(`contact`, requestData).then(response => {
            if (response) {
                commit("SET_CONTACTS", response.data);
            }
            return true;
        });
    },
    async fetchContactsFilter({ commit }, requestData) {
        return await api.get(`contact/contactFilter`, requestData).then(response => {
            if (response) {
                commit("SET_CONTACTS_FILTER",response.data);
            }
            return true;
        });
    },
    async AddNewContact({ commit, dispatch }, contact) {
        return await api.insert('contact', contact).then(response => {
            if (response) {
                commit("ADD_CONTACT", response.data)
                $toast.open({
                    message: 'Contact Added',
                    type: 'success',
                    duration: 3000,
                    dismissible: true
                })
            }
            return true;
        });
    },
    async EditContact({ commit }, patchModel) {
        return await api.patch('contact', patchModel[0], patchModel[1]).then(response => {
            if (response) {
                commit("UPDATE_CONTACT", response.data)
                $toast.open({
                    message: 'Contact Updated',
                    type: 'warning',
                    duration: 3000,
                    dismissible: true
                })
                return true;
            }
        });

    },
    async deleteContact({ commit, dispatch }, contactId) {
        return await api.delete(`contact`, contactId)
            .then((response) => {
                if (response) {
                    $toast.open({
                        message: 'Contact Deleted',
                        type: 'warning',
                        duration: 3000,
                        dismissible: true
                    })
                    commit("REMOVE_CONTACT", contactId);

                    return true;
                }
                return false;
            });
    },
}
export const mutations = {
    SET_CONTACTS(s, contacts) {
        s.contacts = contacts;
        s.isLoading = false;
    },
    SET_CONTACTS_FILTER(s, contacts) {
        s.contactsFilter = contacts;
    },
    ADD_CONTACT(s, contact) {
        s.contacts.items.unshift(contact);
    },
    UPDATE_CONTACT(s, contact) {
        s.contacts.items = s.contacts.items.map((item) => (item.id === contact.id ? contact : item))
    },
    REMOVE_CONTACT(s, id) {
        s.contacts.items = s.contacts.items.filter(item => item.id !== id);

    },
}
export default {
    state,
    getters,
    actions,
    mutations
};